<template>
  <MobileLayout :election="election" v-if="election && breakpoint.less('sm')"/>
  <DesktopLayout :election="election" v-else-if="election && breakpoint.greaterEquals('sm')"/>

</template>

<script>
  import {mapState, mapActions, mapMutations, mapGetters} from 'vuex'
  import visibility from "../../../packs/shared/visibility";
  import MobileLayout from "./layouts/MobileLayout";
  import breakpoint from "../../../packs/shared/breakpoint";
  import DesktopLayout from "./layouts/DesktopLayout";
  import {UniqueChecker} from "../../../packs/shared/unique-checker";

  export default {
    components: {
      DesktopLayout,
      MobileLayout
    },
    data() {
      return {
        visibility,
        breakpoint,
        uniqueChecker: new UniqueChecker()
      }
    },
    computed: {
      ...mapState(['election', 'voting']),
      ...mapGetters(['activeSlide']),
      visible(){
        return this.visibility.visible;
      }
    },
    watch: {
      visible: function(newState, oldState){
        if(newState){
          this.updateStatus();
          this.updatePosts();
        }
      }
    },
    channels: {
      VoterChannel: {
        connected() {
          console.log('Connected to the voter channel');
        },
        received(data){
          console.log('Voter Message received');
          console.log(data);

          // protect against doubled messages
          if(data.uuid && this.uniqueChecker.wasSeen(data.uuid)) return console.log("RECEIVED DOUBLE MESSAGE")

          switch (data.type) {
            case 'update_voter':
              this.$store.commit('updateVoter', data.voter)
              break;
            case 'session_expired':
              data.expire_reason ? this.destroySession(data.expire_reason) : this.destroySession()
              break;
          }
        }
      },
      ElectionsChannel: {
        connected() {
          console.log('Connected to the election channel, updating election -->');
          this.updateStatus(); // voter_counts updated broadcast is not received by the person that just logged in
        },
        received(data) {
          console.log('Election Message received');
          console.log(data);

          // protect against doubled messages
          if(data.uuid && this.uniqueChecker.wasSeen(data.uuid)) return console.log("RECEIVED DOUBLE MESSAGE")

          switch (data.type) {
            case 'goto':
              this.goToSlide(data.id);
              break;
            case 'create_item':
              this.createItem({path: data.path, item: data.item})
              break;
            case 'update_item':
              this.updateItem({path: data.path, changes: data.changes})
              break;
            case 'delete_item':
              this.deleteItem(data.path)
              break;
            case 'state_change':
              this.setBallotState({ballotId: data.ballot_id, ballotState: data.state});
              break;
            case 'ballot_disable':
              this.closeBallot({ballotId: data.ballot_id, time: data.time})
              break;
            case 'progress':
              this.setProgress({ballotId: data.ballot_id, progress: data.progress})
              break
            case 'result_ready':
              this.fetchResult(data.ballot_id)
              break;
            case 'reset_ballot':
              this.resetBallot(data.ballot_id)
              this.setRedoVote(false)
              break
            case 'update':
              this.updatePosts();
              this.updateStatus();
              break;
            case 'update_counts':
              this.setCounts(data.voterCounts)
              break
            default:
              console.log(`received unknown message ${data.type}; updating election`)
              this.updateStatus();
              break;
          }
        }
      }
    },
    methods: {
      ...mapActions(['updateStatus', 'updatePosts', 'fetchResult', 'checkWebsocketConnection']),
      ...mapMutations(['goToSlide', 'setProgress', 'setResult', 'closeBallot', 'setRedoVote', 'resetBallot', 'setCounts', 'setBallotState', 'createItem', 'updateItem', 'deleteItem', 'destroySession']),
    },
    created() {
      this.updateStatus()
      this.updatePosts()
      this.$cable.subscribe({
        channel: 'ElectionsChannel',
        election_id: this.election.id,
        voter_session_uuid: this.voting.voterSessionUuid
      })
      this.$cable.subscribe({
        channel: 'VoterChannel',
        voter_session_uuid: this.voting.voterSessionUuid
      })

    },
    mounted() {
      this.connectiontimer = setInterval(() => {
        this.checkWebsocketConnection()
      }, 1000)
    },
    beforeDestroy() {
      clearInterval(this.connectiontimer)
    }
  }
</script>
