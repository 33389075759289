<template>
  <div class="result container-fluid p-0">
    <template v-if="result && (result.publicised || alwaysShowResult)">
      <h4>{{ $t('js.result.header') }}</h4>
      <template v-if="slide.ruleSet !== 'ranked'">
        <NormalSummary :result="result" :ballot="slide" :theme="theme" :key="slide.id" />
      </template>
      <template v-else-if="slide.ruleSet === 'ranked' && slide.resultMethod === 'prioritization'">
        <RankedSummary :result="result" :ballot="slide" :theme="theme" :key="slide.id" ></RankedSummary>
      </template>
      <template v-else-if="slide.ruleSet === 'ranked'" >
        <NormalSummary :result="result" :ballot="slide" :theme="theme" :key="slide.id"  />
      </template>
      <template v-else-if="result.htmlSummary">
        <div v-html="result.htmlSummary"></div>
      </template>
    </template>
    <h5 class="text-center" v-else>
      {{$t('js.result.status.result_not_public')}}
    </h5>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'
  import Option from "./Option";
  import RankedSummary from "../../shared/RankedSummary"
  import NormalSummary from "../../shared/NormalSummary"

  export default {
    components: {NormalSummary, RankedSummary, Option},
    props: {
      slide: Object,
      header: String,
      alwaysShowResult: {
        type: Boolean,
        default: false
      },
      theme: String
    },
    data(){
      return {
        version: 0
      }
    },
    computed: {
      ...mapState(['election']),
      result(){
        return this.slide.result
      }
    }
  }
</script>

<style>
  .result {
    padding: 0.5em;
    box-sizing: border-box;
  }
</style>
