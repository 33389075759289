<template>
  <div class="col-12" style="overflow-x: auto" v-if="chartType && chartType !== 'none'">
    <horizontal-bars v-if="chartType === 'horizontal_bars'" :theme="theme" :chart-data="chartData" :options="chartOptions" class="option-content w-100 position-relative" style="min-width: 40em"/>
    <vertical-bars v-else-if="chartType === 'vertical_bars'" :theme="theme" :chart-data="chartData" :options="chartOptions" class="option-content w-100 position-relative" :style="{minWidth: Math.max(40, ballotOptions.length*5)+'em'}"/>
    <pie-chart v-else-if="chartType === 'pie'" :theme="theme" :chart-data="chartData" :options="chartOptions" class="option-content w-100 position-relative" style="min-width: 40em"/>
  </div>
</template>
<script>
import HorizontalBars from "./charts/HorizontalBars"
import {mapGetters, mapState} from "vuex";
import VerticalBars from "./charts/VerticalBars";
import PieChart from "./charts/PieChart";

export default {
  name: 'RankedResultChart',
  components: {PieChart, VerticalBars, HorizontalBars},
  props: {
    optionRanks: {
      type: Object,
      required: true
    },
    rounds: {
      type: Array,
      required: true
    },
    distributionNo: {
      type: Number,
      required: true
    },
    ballotOptions: {
      type: Array,
      required: true
    },
    chartType: {
      type: String,
      default: ['horizontal_bars', 'vertical_bars', 'pie', 'none'][1]
    },
    theme: {
      type: String,
      default: 'light'
    },
    chartOptions: {
      type: Object,
      default: ()=>({})
    }
  },
  methods: {
    truncateLabel(label, maxLength=110, ending='…'){
      return label.length > maxLength ?
          label.slice(0,maxLength)+ending :
          label;
    },
    optionLabel(handle){
      let option = this.ballotOptions.find(option => option.handle === handle)
      if(option) {
        return option.title[this.firstAvailableLocale] || Object.values(option.title)[0] || 'missing translation'
      } else {
        return handle
      }
    }
  },
  computed:{
    ...mapGetters(['firstAvailableLocale']),
    ...mapState(['election']),
    themeColor(){
      let themeMatch = this.election.theme.color.match(/hsl\((\d+),\s*(\d+)%,\s*(\d+)%\)/)
      if(!themeMatch) return null
      return {
        hue: themeMatch[1],
        saturation: themeMatch[2],
        lightness: themeMatch[3]
      }
    },
    colors(){
      let countAmount = this.ballotOptions.length
      return this.rounds.map((round, round_index) => [...this.ballotOptions.keys()].map(index => {
        if(this.themeColor){
          return `hsl(${(this.themeColor.hue+(90*round_index)/this.rounds.length)%360}, ${this.themeColor.saturation*(1-index / countAmount)}%, ${this.themeColor.lightness}%)`
        }
        return `hsl(${Math.floor(360 * index / countAmount)}, 65%, 65%)`
      }))
    },
    chartData(){
      let colors = this.colors;
      let handles = this.ballotOptions.map(option => option.handle)
      return {
        labels: handles.map(handle => this.optionLabel(handle)),
        datasets: this.rounds.map((data, round) => {
          return {
            label: this.$t('js.ranked_summary.round_n', {n: round + 1}),
            backgroundColor: colors[round],
            data: handles.map(handle => data.accumulatedCounts[handle] || 0)
          }
        })
      }

    }
  }
}
</script>