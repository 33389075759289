<template>
  <div>
    <div class="overflow-auto bg-white">
      <table class="table">
        <thead class="thead-light">
        <tr>
          <th>{{ $t('js.ranked_summary.position') }}</th>
          <th>{{ $t('js.ranked_summary.candidate') }}</th>
          <th class="text-center" v-for="(round, index) in rounds">{{
              $t('js.ranked_summary.round_n', {n: index + 1})
            }}
          </th>
        </tr>
        </thead>
        <tbody>
        <template v-for="(round, roundIndex) in rounds">
          <tr class="table-success" v-for="(optionId, optionIndex) in round.elected">
            <td>{{ optionPosition(roundIndex, optionIndex) }}</td>
            <td>{{ optionLabel(optionId) }}</td>
            <!-- Vue v-for wont start from 0, therefore we add one before and subtract one when inside the loop -->
            <td class="text-center" v-for="number in roundIndex+1"
                :class="{'font-weight-bold': number === roundIndex+1}">
              {{ rounds[number - 1].accumulatedCounts[optionId] }}
            </td>
            <td v-for="number in (rounds.length - roundIndex - 1)"></td>
          </tr>
        </template>
        </tbody>
        <tbody>
        <tr :class="{'table-warning': tied.includes(option.handle)}" v-for="option in notElected">
          <td>{{ tied.includes(option.handle) ? 'Tied' : '-' }}</td>
          <td>{{ option.title[firstAvailableLocale] }}</td>
          <td class="text-center" v-for="number in rounds.length">
            {{ rounds[number - 1].accumulatedCounts[option.handle] }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div>
      <p>{{ $t('js.ranked_summary.seats') }}: {{ ballot.seats }}</p>
      <p>{{ $t('js.ranked_summary.distribution_no') }}: {{ distributionNo }}</p>
      <p>{{ $t('js.ranked_summary.elected') }}: <span>{{
          elected.map(option => optionLabel(option)).join(', ')
        }} </span></p>
    </div>
    <RankedResultChart :option-ranks="optionRanks" :rounds="rounds" :distribution-no="distributionNo"
                       :ballot-options="options" :chart-type="ballot.resultChart" :theme="theme"/>
  </div>
</template>

<script>
import Option from "../frontend/shared/Option"
import {mapGetters} from "vuex"
import RankedResultChart from "./RankedResultChart"

export default {
  name: "RankedSummary",
  components: {RankedResultChart, Option},
  props: {
    result: Object,
    ballot: Object,
    theme: String
  },
  computed: {
    ...mapGetters(['firstAvailableLocale']),
    optionRanks() {
      return this.result.resultData.optionRanks
    },
    rounds() {
      return this.result.resultData.rounds
    },
    elected() {
      return this.result.resultData.elected
    },
    tied() {
      return this.result.resultData.tied
    },
    distributionNo() {
      return this.result.resultData.distributionNo
    },
    options() {
      return this.ballot.options
    },
    notElected() {
      return this.options.filter(option => !this.elected.includes(option.handle))
          .sort((o1, o2) => this.rounds[this.rounds.length - 1].accumulatedCounts[o2.handle] - this.rounds[this.rounds.length - 1].accumulatedCounts[o1.handle])
    }
  },
  methods: {
    optionLabel(optionHandle) {
      return this.options.filter(option => option.handle === optionHandle.toString())[0].title[this.firstAvailableLocale]
    },
    optionPosition(roundIndex, optionIndex) {
      let position = optionIndex + 1
      for (let i = 0; i < roundIndex; i++) {
        position += this.rounds[i].elected.length
      }
      return position
    },
  }
}
</script>
