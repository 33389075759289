<template>
<button type="button" :disabled="buttonDisabled" @click="onSubmit">
  <slot v-if="!waiting">
    <span>{{ $t('js.actions.submit') }}</span>
  </slot>
  <slot name="waiting" v-else>
    <span><i class="fas fa-spin fa-spinner"></i></span>
  </slot>
</button>
</template>

<script>
export default {
  props: {
    action: {
      type: Function
    },
    forceDisabled: {
      type: Boolean,
      default: false
    },
    waitTime: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    waiting: false,

  }),
  computed: {
    buttonDisabled(){
      return this.waiting || this.forceDisabled
    }
  },
  methods: {
    async onSubmit(){
      this.waiting = true
      await new Promise(res => setTimeout(res, this.waitTime))
      try {
        await Promise.resolve(this.action())
      } finally {
        this.waiting = false
      }
    }
  }
}
</script>