<template>
  <div class="option-result" :class="optionBackground">
    <div class="option-content d-flex justify-content-between" v-tooltip="optionTitle">
      <div class="d-flex align-items-center text-truncate">
        <img :src="option.image" class="turnout-image mr-3" v-if="option.image">
        <header class="h6 text-truncate mb-0 turnout-title">{{ optionTitle }}</header>
      </div>
      <div class="text-right">
        <div class="turnout-count">{{ $n(truncatedVotes) }}</div>
        <div class="text-muted small turnout-percent">{{ $n(optionPercent) }}%</div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"

export default {
  props: {
    option: Object,
    votes: Number,
    total: Number,
    elected: Boolean,
    tied: Boolean
  },
  computed: {
    ...mapGetters(['firstAvailableLocale']),
    optionTitle() {
      return this.option.title[this.firstAvailableLocale] || Object.values(this.option.title)[0] || ""
    },
    optionPercent() {
      return this.total > 0 ? Math.round(this.votes / this.total * 10000) / 100 : 0
    },
    truncatedVotes() {
      return Math.round(this.votes * 100) / 100
    },
    optionBackground() {
      let backgroundColor = 'bg-secondary'
      if (this.elected) {
        backgroundColor = 'alert-success'
      } else if (this.tied) {
        backgroundColor = 'alert-warning'
      }
      return backgroundColor
    }
  }
}
</script>

<style>
.turnout-image {
  max-width: 2em;
  max-height: 2em;
}
</style>
