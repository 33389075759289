import crypto from "../shared/aion_crypto";
import apiClient from "./apiClient";
import { VoteEncryption } from '../shared/vote-encryption';

const defaultState = () => {
    return {
        privateKey: null,
        publicKey: null,
        signature: null,
        voterSessionUuid: null,
        redoVote: false,
        expireReason: null,
        currentVoter: {
            id: null,
            weight: 0,
            votedOn: [],
            canVoteOn: [],
            name: ""
        },
        encryptionConfig: {
            type: 'unencrypted',
            publicKey: null
        }
    }
}
const votingModule = {
    state: defaultState(),
    mutations: {
        startSession(state, { voterSessionUuid, privateKey, encryptionConfig }){
            state.voterSessionUuid = voterSessionUuid
            state.privateKey = privateKey
            state.publicKey = crypto.generateKeyPair(privateKey).public_key
            state.signature = crypto.generateSchnorrSignature('', privateKey)
            state.encryptionConfig = encryptionConfig
        },
        destroySession(state, expireReason = null){
            this.$cable.perform({
                channel: 'VoterChannel',
                action: 'log_out',
                data: {
                    voter_session_uuid: state.voterSessionUuid
                }
            })
            state.voterSessionUuid = null
            state.privateKey = null
            state.publicKey = null
            state.signature = null
            state.expireReason = expireReason
            state.currentVoter = {
                weight: 0,
                canVoteOn: [],
                votedOn: []
            }
            state.encryptionConfig = {
                type: 'unencrypted',
                publicKey: null
            }
        },
        setRedoVote(state, value){
            state.redoVote = value
        },
        setVoted(state, ballotId) {
            if(state.currentVoter.votedOn.includes(ballotId)) return;
            state.currentVoter.votedOn.push(ballotId)
        },
        updateVoter(state, voter) {
            state.currentVoter = voter
        },
        resetExpireReason(state){
            state.expireReason = null
        }
    },
    actions: {
        authenticate({state, commit, rootState, dispatch}, electionCodes){
            let privateKey = electionCodes.map(crypto.electionCodeToPrivateKey).reduce(crypto.addBigNums)
            let signature = crypto.generateSchnorrSignature('', privateKey)
            let publicKey = crypto.generateKeyPair(privateKey).public_key

            return apiClient
                .post(`${rootState.electionUrl}/authenticate`, {
                    public_key: publicKey,
                    signature: signature
                })
                .then(res => {
                    let { voter_session_uuid: voterSessionUuid, encryption_config: encryptionConfig } = res.data
                    if( voterSessionUuid ){
                        commit('startSession', { voterSessionUuid, privateKey, encryptionConfig })
                        //dispatch('updateStatus')
                    }
                }).catch(error =>{
                    if(error.response) {
                        return error.response.data.error;
                    }
                })
        },
        submitVotes({state,commit,rootState}, voteObject){
            let encryptor = new VoteEncryption(state.encryptionConfig).encryptor
            voteObject.votes = encryptor(JSON.stringify(voteObject.votes))
            return apiClient.post(`${rootState.electionUrl}/vote`, {
                ...voteObject,
                signature: state.signature,
                voter_session_uuid: state.voterSessionUuid
            }).then(res => {
                let { success, error } = res.data
                if( success ){
                    commit('setVoted', voteObject.ballot_id)
                    commit('setRedoVote', false)
                }
            }).catch(error =>{
                if(error.response) {
                    return error.response.data.error;
                }
            })
        },
        destroySession({commit}, expireReason = null){
            commit('destroySession', expireReason)
            commit('setPosts', [])
            commit('setSlides', [])
        }
    }
}

export default votingModule;