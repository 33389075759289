<template>
  <main class="side-bar-layout slideshow-background" style="grid-template-columns: minmax(0, 1fr) min-content 1px;">
    <section class="side-bar-main-content pretty-scroll">
      <div class="slide w-100 p-0 m-0">
        <div class="container d-flex flex-column p-2 px-md-5 pt-sm-3 pt-md-5 pb-5">
          <div class="card border-0 rounded-0 shadow">
            <div class="card-header d-flex flex-column flex-column-reverse flex-md-row align-items-stretch bg-theme rounded-0 border-bottom align-items-md-center p-0">
              <div v-if="election.theme.logo" class="bg-light p-3 align-self-stretch d-flex align-items-center">
                <img :src="election.theme.logo" style="max-height:50px;max-width:100%" />
              </div>
              <div class="d-flex flex-grow-1 align-items-center justify-content-between p-3 flex-wrap">
                <h3 class="mb-0">{{election.title[firstAvailableLocale]}}</h3>
                <div class="d-flex align-items-center">
                  <span class="mr-3">{{ voter.name }}</span>
                  <button @click="destroySession" class="btn btn-sm btn-theme-danger mr-2"><i class="fas fa-door-open mr-1"></i>{{ $t('js.actions.sign_out') }}</button>
                  <LocaleSwitcher />
                </div>
              </div>
            </div>

            <div class="card-body p-4 p-md-5 d-flex flex-column">
              <h3 class="text-center mb-3">{{ $t('js.sign_up.sign_up_for')}} {{election.title[firstAvailableLocale]}}</h3>
              <div class="mb-3" v-html="election.sign_up.info[firstAvailableLocale]"></div>
              <transition name="fade" mode="out-in">
                <div v-if="!session.answered" key="not_answered">
                  <div class="d-none d-md-flex justify-content-center">
                    <AsyncButton :action="registerAbsence" class="btn w-50 btn-outline-danger mr-3" :class="isAbsent ? 'active' : ''">
                      {{ $t('js.sign_up.not_participating') }}<br>
                      <span class="small">{{ $t('js.sign_up.not_participating_small') }}</span>
                      <template #waiting>
                        <i class="fas fa-spin fa-spinner"></i>
                      </template>
                    </AsyncButton>
                    <AsyncButton :action="registerParticipation" :class="isParticipating ? 'active' : ''" style="min-width: 100px" class="btn w-50 btn-outline-success">
                      {{ $t('js.sign_up.participating')}}<br>
                      <span class="small">{{ $t('js.sign_up.participating_small') }}</span>
                      <template #waiting>
                        <i class="fas fa-spin fa-spinner"></i>
                      </template>
                    </AsyncButton>
                  </div>
                  <div class="d-md-none">
                    <AsyncButton :action="registerAbsence" class="btn w-100 btn-outline-danger mb-3" :class="isAbsent ? 'active' : ''">
                      {{ $t('js.sign_up.not_participating') }}<br>
                      <span class="small">{{ $t('js.sign_up.not_participating_small') }}</span>
                      <template #waiting>
                        <i class="fas fa-spin fa-spinner"></i>
                      </template>
                    </AsyncButton>
                    <AsyncButton :action="registerParticipation" :class="isParticipating ? 'active' : ''" class="btn w-100 btn-outline-success">
                      {{ $t('js.sign_up.participating')}}<br>
                      <span class="small">{{ $t('js.sign_up.participating_small') }}</span>
                      <template #waiting>
                        <i class="fas fa-spin fa-spinner"></i>
                      </template>
                    </AsyncButton>
                  </div>
                </div>
                <div v-if="session.answered" class="p-3 text-center border" :class="isParticipating ? 'border-success' : 'border-danger'" key="answered">
                  <p class="lead">{{ $t('js.sign_up.thanks') }}</p>
                  <div v-if="isParticipating" class="lead text-success">
                    {{ $t('js.sign_up.participating') }}
                  </div>
                  <div v-if="isAbsent" class="text-danger lead">
                    {{ $t('js.sign_up.not_participating') }}
                  </div>

                  <div class="mt-3">
                    <button @click="changeAnswer()" class="btn btn-sm btn-outline-dark">{{ $t('js.sign_up.change_answer') }}</button>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import SideBarButton from "./sidebar/SideBarButton";
import LocaleTab from "./../../shared/LocaleTab";
import LocaleSwitcher from "./../shared/LocaleSwitcher"
import apiClient from "../../../packs/frontend/apiClient"
import AsyncButton from "../../shared/AsyncButton"

export default {
  name: 'SignUp',
  components: {
    AsyncButton,
    LocaleSwitcher,
    LocaleTab, SideBarButton
  },
  computed: {
    ...mapGetters(['firstAvailableLocale']),
    ...mapState(['election', 'electionUrl', 'session']),
    availableLocales(){
      return this.$i18n.availableLocales;
    },
    isParticipating(){
      return this.voter.participating
    },
    isAbsent(){
      return this.voter.participating !== null && !this.voter.participating
    },
    voter(){
      return this.session.voter
    }
  },
  methods: {
    ...mapActions(['destroySession', 'submitAnswer']),
    ...mapMutations(['setAnswered']),
    registerParticipation(){
      this.submitAnswer(true)
    },
    registerAbsence(){
      this.submitAnswer(false)
    },
    changeAnswer(){
      this.setAnswered(null)
    }
  }
}
</script>
